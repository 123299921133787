import { default as abuseReportsHKLkro5vqnMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93kG3sGZ3ao9Meta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as indexMVmJkL71sFMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/index.vue?macro=true";
import { default as moderationJyYsmPnLi2Meta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/moderation.vue?macro=true";
import { default as pendingBans79YAnpng9JMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/pendingBans.vue?macro=true";
import { default as profilesTW3WWAqUmoMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/profiles.vue?macro=true";
import { default as expensesNrihTdqksJMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexqRsU8ntwj4Meta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewQSEY9I4WlZMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingYeaToqiqNqMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingSdTXE70t45Meta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/translations/missing.vue?macro=true";
import { default as usersk94qiW49tRMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/users.vue?macro=true";
import { default as api7seqLPEjePMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/api.vue?macro=true";
import { default as _91slug_93fYUbxIR30HMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/blog/[slug].vue?macro=true";
import { default as indexxzsD0cqCWeMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93xJF6m9AV9lMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93ghbuNSZkEmMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminSyKtbzcWXBMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/census/admin.vue?macro=true";
import { default as indexB9aQCg9SwkMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/census/index.vue?macro=true";
import { default as contactEOFpo4HC3oMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/contact.vue?macro=true";
import { default as designYopcaUzM38Meta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/design.vue?macro=true";
import { default as english3fwVPmXBvSMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/english.vue?macro=true";
import { default as faqgaHNUQW73pMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/faq.vue?macro=true";
import { default as inclusiveTF5Ub0Eu3eMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/inclusive.vue?macro=true";
import { default as indexIztOOFFPBOMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/index.vue?macro=true";
import { default as licenseToRD2RE5PCMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/license.vue?macro=true";
import { default as academicZPul5Aj00KMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/links/academic.vue?macro=true";
import { default as indexrlB8OXZ9woMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/links/index.vue?macro=true";
import { default as mediaVhW2DufIZaMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/links/media.vue?macro=true";
import { default as translinguisticsJYg9fPo0jTMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/links/translinguistics.vue?macro=true";
import { default as zineeS03NfFVwTMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/links/zine.vue?macro=true";
import { default as namespNV6JsoWtfMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/names.vue?macro=true";
import { default as indexPyLqnXTizEMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/nouns/index.vue?macro=true";
import { default as templates8Q4QmvIhL8Meta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/nouns/templates.vue?macro=true";
import { default as peopleEhvYLpCR7VMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/people.vue?macro=true";
import { default as privacy1Tdizjm8rsMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/privacy.vue?macro=true";
import { default as _91username_93mTIay8czZKMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93UtE59g7mclMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/profile/card-[username].vue?macro=true";
import { default as editoreQX07HeSwsMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/profile/editor.vue?macro=true";
import { default as anyZnwEPmwwUHMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/pronouns/any.vue?macro=true";
import { default as avoidingRHF1xq2dQjMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/pronouns/avoiding.vue?macro=true";
import { default as indexLKaXH8ax7AMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/pronouns/index.vue?macro=true";
import { default as mirrorfVl08tlygwMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/pronouns/mirror.vue?macro=true";
import { default as pronoungmtz08PynOMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/pronouns/pronoun.vue?macro=true";
import { default as sources1YrhU8CK1iMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/sources.vue?macro=true";
import { default as team0UgvsVCskgMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/team.vue?macro=true";
import { default as terminologyafMmekWcftMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/terminology.vue?macro=true";
import { default as termsw6lBzvblD9Meta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/terms.vue?macro=true";
import { default as userpTqzNrU1D0Meta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/user.vue?macro=true";
import { default as workshopsODmRgnlhwwMeta } from "/home/admin/www/pronoms.fr/release/20241012153112/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsHKLkro5vqnMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBans79YAnpng9JMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: api7seqLPEjePMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93fYUbxIR30HMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexxzsD0cqCWeMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93xJF6m9AV9lMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93ghbuNSZkEmMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminSyKtbzcWXBMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexB9aQCg9SwkMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactEOFpo4HC3oMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: english3fwVPmXBvSMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqgaHNUQW73pMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveTF5Ub0Eu3eMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicZPul5Aj00KMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexrlB8OXZ9woMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaVhW2DufIZaMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsJYg9fPo0jTMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineeS03NfFVwTMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namespNV6JsoWtfMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexPyLqnXTizEMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templates8Q4QmvIhL8Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleEhvYLpCR7VMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacy1Tdizjm8rsMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93mTIay8czZKMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93UtE59g7mclMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editoreQX07HeSwsMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyZnwEPmwwUHMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingRHF1xq2dQjMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexLKaXH8ax7AMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorfVl08tlygwMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronoungmtz08PynOMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sources1YrhU8CK1iMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: team0UgvsVCskgMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyafMmekWcftMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsw6lBzvblD9Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userpTqzNrU1D0Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsODmRgnlhwwMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241012153112/pages/workshops.vue")
  }
]