import validate from "/home/admin/www/pronoms.fr/release/20241012153112/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@8.57.1_rollup@4.22.2_sass@1.32.12__ydqwum6exgwbk22mhj5oetqsgm/node_modules/nuxt/dist/pages/runtime/validate.js";
import atom_45global from "/home/admin/www/pronoms.fr/release/20241012153112/middleware/atom.global.ts";
import redirect_45global from "/home/admin/www/pronoms.fr/release/20241012153112/middleware/redirect.global.ts";
import manifest_45route_45rule from "/home/admin/www/pronoms.fr/release/20241012153112/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@8.57.1_rollup@4.22.2_sass@1.32.12__ydqwum6exgwbk22mhj5oetqsgm/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  atom_45global,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "layout-from-query": () => import("/home/admin/www/pronoms.fr/release/20241012153112/middleware/layoutFromQuery.ts")
}